.menu-bar {
    width: 100%;
    background-color: #333;
    overflow: hidden;
    position: fixed;
    bottom: 0;
    z-index: 1000;
  }
  
  .menu-bar ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: space-around;
  }
  
  .menu-bar li {
    float: left;
  }
  
  .menu-bar li a {
    display: block;
    color: white;
    text-align: center;
    padding: 14px 20px;
    text-decoration: none;
  }
  
  .menu-bar li a:hover {
    background-color: #111;
  }

  .menu-bar img {
    width: 40px;
    height: 40px;
    margin-top: 5px;
  }
  
  @media (max-width: 600px) {

    .menu-bar {
        width: 100%;
        background-color: #333;
        overflow: hidden;
        position: fixed;
        bottom: 0;
        z-index: 1000;
      }
      
      .menu-bar ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
        display: flex;
        justify-content: space-around;
      }
      
      .menu-bar li {
        float: left;
      }
      
      .menu-bar li a {
        display: block;
        color: white;
        text-align: center;
        padding: 14px 20px;
        text-decoration: none;
        font-size:14px;
      }
      
      .menu-bar li a:hover {
        background-color: #111;
      }
    
      .menu-bar img {
        width: 40px;
        height: 40px;
        margin-top: 5px;
      }

    }